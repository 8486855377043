import { useRouterGenerator, useI18nService, IPortalRouter } from '@zephyr/framework'

export const createRoutes = async (portalRoutes: IPortalRouter[]) => {
  const { generateRoutes } = useRouterGenerator()
  const i18n = useI18nService()
  const additionalRoutes: IPortalRouter[] = [
    ...(portalRoutes ?? [])
  ]

  const loadedRoutes = await generateRoutes(additionalRoutes)

  const routes = [
    {
      path: '/',
      name: 'root',
      component: () => import('@/layouts/MainLayout/MainLayout.vue'),
      children: [
        {
          path: 'no-access',
          name: 'no-access-logged-in',
          component: () => import('@/pages/403/index.vue')
        },
        {
          path: ':workspace/dashboards',
          props: true,
          meta: {
            isDashboard: true
          },
          name: 'home',
          component: () => import('@/pages/Section.vue'),
          children: [
            {
              path: ':code*',
              name: 'dashboard',
              props: true,
              meta: {
                title: i18n.t('dashboard.title'),
                isDashboard: true
              },
              component: () => import('@zephyr/dashboards/dashboard')
            },
          ]
        },
        {
          path: 'profile',
          name: 'profile',
          redirect: {
            name: 'profile-view'
          },
          meta: {
            title: 'Профиль',
            options: {
              menuCode: 'profile',
            }
          },
          component: () => import('@/pages/Section.vue'),
          children: [
            {
              path: 'view',
              name: 'profile-view',
              meta: {
                title: 'Просмотр'
              },
              component: () => import('@/pages/Profile/Profile.vue')
            }
          ]
        },
        // ...workspaces
      ],
    },
    {
      path: '/auth',
      component: () => import('@/layouts/AuthLayout/AuthLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/IAM/Login/Login.vue')
        }
      ]
    },
    {
      path: '/no-access',
      name: 'no-access',
      component: () => import('@/pages/403/index.vue')
    },
    {
      path: '/:catchAll(.*)*',
      name: 'not-found',
      component: () => import('@/pages/404/index.vue')
    }
  ]

  if (loadedRoutes?.length) {
    const rootRoute = routes.find(item => item.name === 'root')
    if (rootRoute && !rootRoute?.children?.length) {
      rootRoute.children = []
    }
    rootRoute?.children.push(...loadedRoutes)
  }

  console.log('Create routes => ', routes)

  return routes
}
