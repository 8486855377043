import { createRouter, createWebHistory } from 'vue-router'

import qs from 'qs'
import { useEndpoint, IPortalRouter, useWorkspace, usePermissions } from '@zephyr/framework'
import { createRoutes } from './routes'
import { useProfile } from '@/composable/use-profile/useProfile'
import { useDashboardConfig } from '@zephyr/dashboards'

const stringify = (str) => {
  return qs.stringify(str, { encode: false })
}

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
  }
}

export const initRouter = async (portalRoutes: IPortalRouter[]) => {
  const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return new Promise((resolve, _reject) => {
          setTimeout(() => {
            resolve({ el: to.hash })
          }, 500)
        })
      }
      if (savedPosition) {
        return savedPosition
      }
      if (to.meta.noScroll && from.meta.noScroll) {
        return {}
      }
      return { top: 0 }
    },
    routes: await createRoutes(portalRoutes),

    parseQuery: qs.parse,
    stringifyQuery: stringify
  })

  router.beforeEach(async (to, from) => {
    const { getAuthFlow } = useEndpoint()
    const { checkPermissionList, updating } = usePermissions()
    const { checkLoggedIn } = getAuthFlow()
    const isLoggedIn = await checkLoggedIn()
    const permissionsRequired = to?.meta?.permissions?.required

    console.time('Tenant Index Routing - waiting for updating permissions')
    await updating
    console.timeEnd('Tenant Index Routing - updating permissions done')

    if (isLoggedIn) {
      const permissionsState = checkPermissionList(permissionsRequired)
      console.log('Tenant Index permissionsState', permissionsState)
      if (!permissionsState) {
        return { name: 'no-access-logged-in' }
      }
      console.log(`Route to ${to.name} is ${isLoggedIn ? 'allowed' : 'denied'}`, permissionsRequired)

      const { getProfile, profile } = useProfile()
      if (!profile.value.id && to.name !== 'login' || from.name === 'login') {
        console.log('Before Each - get profile', { profileID: profile.value.id, toName: to.name, fromName: from.name })
        await getProfile()
      }
    } else if (to.name !== 'login') {
      console.log('Tenant Index Routing - not logged in')
      return {
        name: 'login'
      }
    }

    // отработка навигации по дашбордам
    if ([ 'home', 'root' ].includes(to.name)) {
      console.log('Dashboard routing: ', { to, from })
      const { currentWorkspace } = useWorkspace()
      const workspace = to.params?.workspace ?? currentWorkspace.value?.code
      const code = to.params?.code

      if (workspace && code) {
        return true
      }

      if (workspace) {
        const { currentDashboard } = useDashboardConfig({ currentWorkspace: workspace, ignoreRoute: true })
        const redirectRoute = {
          name: 'dashboard',
          params: {
            code: currentDashboard.value?.code,
            workspace: workspace
          }
        }
        console.log('Dashboard -> Redirect to workspace: ', redirectRoute)
        return redirectRoute
      }
    } else if (to.name === 'dashboard') {
      const { currentWorkspace } = useWorkspace()
      const workspace = to.params?.workspace ?? currentWorkspace.value?.code

      useDashboardConfig({ currentWorkspace: workspace })
    }

    return true
  })

  return router
}